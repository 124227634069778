import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card, CardBody } from '@paljs/ui/Card';
import { Editor } from '@tinymce/tinymce-react';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ARTICLE_MUTATION = gql`
  mutation onNewCoffeeProfile(
    $name: String!
    $email: String!
    $beansOrGrounds: String
    $roastLevelPreference: String
    $comments: String
  ) {
    onNewCoffeeProfile(
      name: $name
      email: $email
      beansOrGrounds: $beansOrGrounds
      roastLevelPreference: $roastLevelPreference
    )
  }
`;

const EditArticles = () => {
  if (typeof window === 'undefined') {
    return <div>loading..</div>;
  }
  console.log(document.location.search.substring(1));

  let params = new URLSearchParams(document.location.search.substring(1));

  let key = params.get('key');

  const q = gql`
    query articleByKey($key: String!) {
      articleByKey(key: $key) {
        title
        intro
        image
        slug
        content
        topics {
          slug
        }
        _key
      }
    }
  `;

  const { data, loading, error } = useQuery(q, {
    variables: {
      key,
    },
  });

  if (loading) {
    return <>loading...</>;
  }

  if (error) {
    console.log(error);
    return <>error...</>;
  }

  const article = data.articleByKey;

  const handleEditorChange = (content: any, _editor: any) => {
    console.log('Content was updated:', content);
  };

  if (!article) {
    return <div>No article. you dunghole</div>;
  } else {
    let topics = article.topics;
    console.log(topics);
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h2 style={{ textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em' }}>
            Edit Article
          </h2>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <header>Title:</header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Size Medium" value={article.title} />
              </Input>
            </CardBody>
          </Card>
          <Card>
            <header>Intro:</header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Size Medium" value={article.intro} />
              </Input>
            </CardBody>
          </Card>
          <Card>
            <header>Slug:</header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Size Medium" value={article.slug} />
              </Input>
            </CardBody>
          </Card>
          <Card>
            <header>Image:</header>
            <CardBody>
              <Input fullWidth size="Medium">
                <input type="text" placeholder="Size Medium" value={article.image} />
              </Input>
            </CardBody>
          </Card>
          <Card>
            <header>Content:</header>
            <CardBody>
              <Editor
                initialValue={article.content}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help',
                }}
                onEditorChange={handleEditorChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditArticles;
